import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { addComma } from "../../../../Monetise/Monetise/useDashboard";
import { ReactComponent as DownIcon } from "../../../../../assets/svg/downicon.svg";
import { ReactComponent as UpIcon } from "../../../../../assets/svg/uparr.svg";
import styles from './styles.module.scss';
import { SalesChart } from "./linechart";

const getTrendIcon = (value: number) => {
    if (value < 0) {
        return <DownIcon />
    } else if (value === 0) {
        return null;
    } else {
        return <UpIcon />
    }
};

const getTextColor = (value: number) => {
    if (value <= 0) {
        return '#F60000';
    } else {
        return '#03A01C';
    }
};

export const KpiSection = () => {
    const kpiData = [
        {
            title: 'Repeat Product Sales',
            content: addComma(72318),
            footer: <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                {getTrendIcon(0)}
                <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(56300) || 0 }}>{56300 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 4</span></p>
            </div>,
            chartData: [150000, 250000, 300000, 350000, 270000, 290000, 310000, 260000, 290000, 250000]
        },
        {
            title: 'Repeat Customers',
            content: `$ ${addComma(72318)}`,
            footer: <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                {getTrendIcon(0)}
                <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(56300) || 0 }}>{56300 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 4</span></p>
            </div>,
            chartData: [100000, 150000, 200000, 150000, 180000, 160000, 190000, 170000, 140000, 160000]
        },
        {
            title: 'Repeat Order Units',
            content: `$ ${addComma(72593)}`,
            footer: <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                {getTrendIcon(0)}
                <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(56300) || 0 }}>{56300 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 4</span></p>
            </div>,
            chartData: [125000, 170000, 250000, 130000, 160000, 190000, 170000, 175000, 180000, 155000]
        },
        {
            title: 'Repeat Customer Share',
            content: addComma(152),
            footer: <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                {getTrendIcon(0)}
                <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(56300) || 0 }}>{56300 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 4</span></p>
            </div>,
            chartData: [95000, 120000, 130000, 110000, 115000, 145000, 135000, 160000, 155000, 175000]
        }
    ];

    return (
        <Grid className={styles.newSmallCards}>
            {kpiData.map((card, index) => (
                <Grid className={styles.newSmallCard} key={index}>
                    <Grid className={styles.cardContent}>
                        <Typography className={styles.cardTitle}>{card?.title}</Typography>
                        <Typography className={styles.cardText}>{card?.content}</Typography>
                        <Grid style={{ background: '#F7F7F7', borderRadius: '11px', padding: '6px' }}>
                            <p>{card.footer}</p>
                        </Grid>
                            <SalesChart label={card.title} data={card.chartData} />
                    </Grid>
                </Grid>
            ))}
        </Grid>

    );
};
