import GroupedBarChart from "../../../../../components/molecules/bar-chart";
import { addComma } from "../../useDashboard";
import Loader from "../../../../../components/atoms/loader";
export const BarchartPromotions = ({ data, loader, width = 'fit-content', height = 'fit-content' }: any) => {
    const getData = () => {
        const labels: any = data?.dateLabels
        const datasets: any = [
            {
                label: 'Promotional',
                data: data?.promos,
                backgroundColor: ['rgb(139, 151, 255)'],
                borderColor: ['rgb(139, 151, 255)'],
                barPercentage: 0.4,
            },
            {
                label: 'Non-Promotional',
                data: data?.nonPromos,
                backgroundColor: ['rgb(133, 216, 221)'],
                borderColor: ['rgb(133, 216, 221)'],
                barPercentage: 0.4,
            },
        ]
        return {
            labels: labels,
            datasets: datasets
        }
    }

    const toptions = {
        chart: {
            type: 'bar',
            height: '100%', // Set height to 100% for responsiveness
            width: '100%',  // Set width to 100% for responsiveness
        },
        colors: ["#1b3c7a", "#427ae3", "#3dc7d1", '#faa93e'],
        fill: {
            colors: ["#1b3c7a", "#427ae3", "#3dc7d1", '#faa93e']
        },
        maintainAspectRatio: false,  // Added here to allow free resizing
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    fontColor: '#333',
                    usePointStyle: true,
                }
            },
            datalabels: {
                display: false,
                color: "black",
                formatter: function (context: any) {
                    if (typeof context === 'number') {
                        return context;
                    } else {
                        return context[1] - context[0];
                    }
                },
                anchor: "end",
                offset: 10,
                align: "start",
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
                barPercentage: 0.5,
                categorySpacing: 0,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        scales: {
            y: {
                min: 0,
                title: {
                    display: false,
                    text: 'Net Sales',
                    color: 'black',
                    fontWeight: 700,
                    padding: 5,
                },
                stacked: true,
                ticks: {
                    callback: (value: any) => {
                        if (value >= 1000) {
                            return `$ ${addComma(value / 1000)}K`;
                        }
                        return value;
                    },
                    fontSize: '10px',
                },
            },
            x: {
                barPercentage: 0.5,
                categorySpacing: 0,
                stacked: true,
                grid: {
                    display: false,
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (context: any) {
                    if (typeof context === 'number') {
                        return context;
                    } else {
                        return context[1] - context[0];
                    }
                },
            },
        },
    };


    return (
        <div style={{ padding: '12px' }} className='chart-height'>
            {!loader ? <GroupedBarChart {...{
                toptions,
                data: getData(),
                width: width,
                height
            }} /> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Loader size={40} />
            </div>}
        </div>
    )
}