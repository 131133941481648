import React, { useEffect, useState } from 'react'
import HorizontalOrgChart from '../../../../../components/molecules/horizontal-org-chart'
import employees from '../../../../../components/molecules/horizontal-org-chart/data'

const OrgChart = ({style, translate, treeData}: any) => {
  return (
    <HorizontalOrgChart 
        {...{
            style,
            translate,
            treeData
        }}
    />
  )
}

export default OrgChart