import Loader from "../../../../../components/atoms/loader"
import GroupedBarChart from "../../../../../components/molecules/bar-chart"
import { addComma } from "../../../../Monetise/Monetise/useDashboard"
import styles from './styles.module.scss'
export const PromotionsChart = ({ data1, loader, width = 'fit-content', height = 'fit-content' }: any) => {
    const data = {
        "dateLabels": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        "promos": [50000, 70000, 80000, 90000, 100000, 110000, 120000, 130000, 140000, 150000, 160000, 170000],
        "nonPromos": [30000, 50000, 60000, 70000, 80000, 90000, 100000, 110000, 120000, 130000, 140000, 150000]
    }

    const getData = () => {
        const labels: any = data?.dateLabels
        const datasets: any = [
            {
                label: 'Promo',
                data: data?.promos,
                backgroundColor: ['#5654D4'], // Light blue for Promo
                borderColor: ['#5654D4'],
                barPercentage: 0.4,
            },
            {
                label: 'Non-Promo',
                data: data?.nonPromos,
                backgroundColor: ['#3FC8E4'], // Darker blue for Non-Promo
                borderColor: ['#3FC8E4'],
                barPercentage: 0.4,
            },
        ]
        return {
            labels: labels,
            datasets: datasets
        }
    }

    const toptions = {
        chart: {
            type: 'bar',
            height: '100%', // Set height to 100% for responsiveness
            width: '100%',  // Set width to 100% for responsiveness
        },
        colors: ["#85D8DD", "#1b3c7a"], // Matching the colors used in the image
        fill: {
            colors: ["#85D8DD", "#1b3c7a"]
        },
        maintainAspectRatio: false,  // Added here to allow free resizing
        plugins: {
            legend: {
                display: false,
                position: 'top', // Adjust legend position
                labels: {
                    fontColor: '#333',
                    usePointStyle: true,
                }
            },
            datalabels: {
                display: false, // No labels on the bars in the image
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
                barPercentage: 0.5,
                categorySpacing: 0,
                stacked: true, // Stack the bars
            },
        },
        scales: {
            y: {
                min: 0,
                title: {
                    display: false, // No y-axis title
                    text: 'Net Sales',
                    color: 'black',
                    fontWeight: 700,
                    padding: 5,
                },
                stacked: true, // Stacked configuration
                ticks: {
                    callback: (value: any) => {
                        if (value >= 1000) {
                            return `$ ${addComma(value / 1000)}K`;
                        }
                        return `$ ${addComma(value)}`;
                    },
                    fontSize: '10px',
                },
            },
            x: {
                stacked: true, // Ensure x-axis bars are also stacked
                grid: {
                    display: false, // No gridlines
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (context: any) {
                    return `$ ${addComma(context)}`;
                },
            },
        },
    };

    return (
        <div style={{ padding: '12px',height:'90%' }}>
            <div className={styles.container}>
                <div className={styles.item}>
                    <div className={styles.sales}>
                        Promo
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.profit}>
                        Non-Promo
                    </div>
                </div>
            </div>
            {!loader ? <GroupedBarChart {...{
                toptions,
                data: getData(),
                width: width,
                height
            }} /> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Loader size={40} />
            </div>}
        </div>
    )
}
