import React from 'react';
import styles from './styles.module.scss'; // Updated to SCSS module
import { ReactComponent as FirstPolygon } from '../../../../../assets/svg/shopper/first-polygon.svg';
import { ReactComponent as SecondPolygon } from '../../../../../assets/svg/shopper/second-polygon.svg';
import { ReactComponent as ThirdPolygon } from '../../../../../assets/svg/shopper/third-polygon.svg';
import { ReactComponent as FourthPolygon } from '../../../../../assets/svg/shopper/fourth-polygon.svg';
import { ReactComponent as FifthPolygon } from '../../../../../assets/svg/shopper/fifth-polygon.svg';

const layers = [
  { Component: FirstPolygon, label: 'Impressions', maxWidth: 350 },
  { Component: SecondPolygon, label: 'Clicks', maxWidth: 280 },
  { Component: ThirdPolygon, label: 'Add to Carts', maxWidth: 230 },
  { Component: FourthPolygon, label: 'Purchases', maxWidth: 180 },
  { Component: FifthPolygon, label: 'Repeats', maxWidth: 110 }
];

const PyramidChart = () => {
  return (
    <div className={styles.chartContainer}>
      <div className={styles.funnelChart}>
        {layers.map((layer, index) => {
          const { Component } = layer;
          return (
            <div key={index} className={styles.funnelLayer} style={{ maxWidth: `${layer.maxWidth}px` }}>
              <Component style={{ width: '100%', height: index == 4 ? '100%' : 'auto' }} />
              <div className={styles.funnelText}>{layer.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PyramidChart;
