import { useEffect, useState } from "react"
import { getDashboardLoyalityData, getDashboardNumericals, getDashboardTreeData, getDashboardCustomerData, getDashboardPromotionsData, getDashboardBestSellers, getDashboardMap } from "../../../services/insights";
import { ReactComponent as TrendingUpIcon } from '../../../assets/svg/trendingup.svg';
import { ReactComponent as UpIcon } from "../../../assets/svg/uparr.svg";
import { ReactComponent as DownIcon } from "../../../assets/svg/downicon.svg";
import { getUtilsData, getCustomerBrandData } from "../../../services/insights";
import { useSelector, useDispatch } from 'react-redux'
import { timeline, usStateData } from "../../../constants/utils";
import { getAutoStack } from "../../../services/insights";

const getTrendIcon = (value: number) => {
    if (value < 0) {
        return <DownIcon />
    } else if (value === 0) {
        return null;
    }
    else {
        return <UpIcon />
    }
}

const getTextColor = (value: number) => {
    if (value <= 0) {
        return '#F60000';
    } else {
        return '#03A01C'
    }
}

export const ceilNumber = (value: number) => {
    return Math.ceil(value * 10) / 10;
}

export const addComma = (value: number) => {
    return value.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const formatDate = (dateStr: string) => {
    const [year, monthIndex] = dateStr?.split('-');
    const month = months[parseInt(monthIndex) - 1];
    return `${month} ${year}`;
};

export const useDashboard = () => {
    const categories = useSelector((state: any) => state?.sidebarContext?.category);
    const delivery = useSelector((state: any) => state?.sidebarContext?.delivery);
    const brand = useSelector((state: any) => state?.sidebarContext?.brand);
    const location = useSelector((state: any) => state?.sidebarContext?.location);
    const age_group = useSelector((state: any) => state?.sidebarContext?.age_group);
    const products = useSelector((state: any) => state?.sidebarContext?.products);

    const [numericals, setNumericals] = useState<any>([]);
    const diagnoseKpis: any = [
        {
            title: 'Repeat Product Sales',
            content: addComma(72318) || 0,
            footer: (
                <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                    {getTrendIcon(0)}
                    <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(56300) || 0 }}>{56300 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 4</span></p>
                </div>
            )
        },
        {
            title: 'Repeat Customers',
            content: `$ ${addComma(72318)}`,
            footer: (
                <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                    {getTrendIcon(56200 || 0)}
                    <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(58222) }}>{56300 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 3</span></p>
                </div>
            )
        },
        {
            title: 'Repeat Order Units',
            content: `$ ${addComma(72593 || 0)}`,
            footer: (
                <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                    {getTrendIcon(59022)}
                    <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(58222 || 0) }}>{58222 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 2.5</span></p>
                </div>
            )
        },
        {
            title: 'Repeat Customer Share',
            content: addComma(152) || 0,
            footer: (
                <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                    {getTrendIcon(135 || 0)}
                    <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(132) }}>{132}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 5</span></p>
                </div>
            )
        }
    ]
    const actionKpis: any = [
        {
            title: 'Income',
            content: addComma(72318) || 0,
            footer: (
                <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                    {getTrendIcon(0)}
                    <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(56300) || 0 }}>{56300 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 4</span></p>
                </div>
            )
        },
        {
            title: 'Age',
            content: `$ ${addComma(72318)}`,
            footer: (
                <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                    {getTrendIcon(56200 || 0)}
                    <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(58222) }}>{56300 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 3</span></p>
                </div>
            )
        },
        {
            title: 'Gender',
            content: `$ ${addComma(72593 || 0)}`,
            footer: (
                <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                    {getTrendIcon(59022)}
                    <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(58222 || 0) }}>{58222 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 2.5</span></p>
                </div>
            )
        },
        {
            title: 'Education',
            content: addComma(152) || 0,
            footer: (
                <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                    {getTrendIcon(135 || 0)}
                    <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(132) }}>{132}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 5</span></p>
                </div>
            )
        },
        // {
        //     title: 'Marital Status',
        //     content: addComma(152) || 0,
        //     footer: (
        //         <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
        //             {getTrendIcon(135 || 0)}
        //             <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(132) }}>{132}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 5</span></p>
        //         </div>
        //     )
        // }
    ]
    const [loader, setLoader] = useState<{
        cards?: boolean,
        chart?: boolean,
        map?: boolean,
        segmentation?: boolean,
        loyalty?: boolean,
        customer?: boolean,
        promotions?: boolean,
        bestsellers?: boolean
    }>({
        cards: false,
        chart: false,
        map: false,
        segmentation: false,
        loyalty: false,
        customer: false,
        promotions: false,
        bestsellers: false
    });
    const [cardsLoader, setCardsLoader] = useState(false);
    const [chartLoader, setChartLoader] = useState(false);
    const [segmentationLoader, setSegmentationLoader] = useState(false);
    const [loyaltyLoader, setLoyaltyLoader] = useState(false);
    const [customerLoader, setCustomerLoader] = useState(false);
    const [promotionsLoader, setPromotionsLoader] = useState(false);
    const [bestsellersLoader, setBestsellersLoader] = useState(false);
    const [mapsLoader, setMapsLoader] = useState(false);

    const [brands, setBrands] = useState<any>([]);
    const [customerBrands, setCustomerBrands] = useState<any>([]);
    const [selectBrand, setSelectBrand] = useState<{
        tree?: string;
        loyality?: string;
        customer?: string;
        promotion?: string;
    }>({
        tree: 'technology',
        loyality: brands[0]?.value,
        customer: customerBrands[0]?.value,
        promotion: brands[0]?.value
    });
    const [treeData, setTreeData] = useState<any>({});
    const [loyalityData, setLoyalityData] = useState<any>([]);
    const [customerSwitchData, setCustomerSwitchData] = useState<any>([]);
    const [promotionsData, setPromotionsData] = useState<any>({
        dateLabels: [],
        promos: [],
        nonPromos: []
    });
    const [bestSellers, setBestSellers] = useState<any>([]);
    const [mapsData, setMapsData] = useState<any>({});
    const [segmentationData, setSegmentationData] = useState<any>([]);

    const [timeLines, setTimeLines] = useState<any>(timeline?.map((time: string) => {
        return {
            label: time,
            value: time
        }
    }));
    const [allTimes, setAllTimes] = useState<any>({
        cards: timeLines[5]?.value,
        chart: timeLines[5]?.value,
        map: timeLines[5]?.value,
        segmentation: timeLines[5]?.value,
        loyalty: timeLines[5]?.value,
        customer: timeLines[5]?.value,
        promotions: timeLines[5]?.value,
        bestseller: timeLines[5]?.value
    });

    const getBrands = async () => {
        const response: any = await getUtilsData('brand_name/?offset=0&limit=10');
        const brandResult = response?.results?.map((brand: string) => {
            return {
                label: brand,
                value: brand
            }
        }) || [];
        setBrands([...brandResult]);
    }

    const getCustomerBrands = async () => {
        const response: any = await getCustomerBrandData();
        const brandResult = response?.results?.map((brand: string) => {
            return {
                label: brand,
                value: brand
            }
        }) || [];
        setCustomerBrands([...brandResult]);
    }

    const getNumericalsData = async () => {
        try {
            setCardsLoader(true)
            const response: any = await getDashboardNumericals({
                products: categories,
                lastKey: [allTimes?.cards]
            });
            const items = response?.[categories[0]?.toLowerCase()]?.[0];
            const cardsData: any = [
                {
                    title: 'Number of buyers',
                    content: addComma(items?.total_customers) || 0,
                    footer: (
                        <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                            {getTrendIcon(Math.ceil(items?.change_in_customers * 10) / 10 || 0)}
                            <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(Math.ceil(items?.change_in_customers * 10) / 10) || 0 }}>{Math.ceil(items?.change_in_customers * 10) / 10 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 4</span></p>
                        </div>
                    )
                },
                {
                    title: 'Total Orders',
                    content: `$ ${addComma(Math.ceil(items?.total_orders * 10) / 10 || 0)}`,
                    footer: (
                        <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                            {getTrendIcon(Math.ceil(items?.change_in_orders * 10) / 10 || 0)}
                            <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(Math.ceil(items?.change_in_orders * 10) / 10 || 0) }}>{Math.ceil(items?.change_in_orders * 10) / 10 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 3</span></p>
                        </div>
                    )
                },
                {
                    title: 'Total sales value',
                    content: `$ ${addComma(Math.ceil(items?.total_amount * 10) / 10 || 0)}`,
                    footer: (
                        <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                            {getTrendIcon(Math.ceil(items?.change_in_amount * 10) / 10 || 0)}
                            <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(Math.ceil(items?.change_in_amount * 10) / 10 || 0) }}>{Math.ceil(items?.change_in_amount * 10) / 10 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 2.5</span></p>
                        </div>
                    )
                },
                {
                    title: 'Trips',
                    content: addComma(Math.ceil(items?.average_round_trips * 10) / 10) || 0,
                    footer: (
                        <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                            {getTrendIcon(Math.ceil(items?.change_in_average_round_trips * 10) / 10 || 0)}
                            <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(Math.ceil(items?.change_in_average_round_trips * 10) / 10 || 0) }}>{Math.ceil(items?.change_in_average_round_trips * 10) / 10 || 0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 5</span></p>
                        </div>
                    )
                }
            ]
            setNumericals(cardsData)
            setCardsLoader(false)
        } catch (error) {
            setCardsLoader(true)
            const cardsData: any = [
                {
                    title: 'Number of buyers',
                    content: 0,
                    footer: (
                        <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                            {getTrendIcon(0)}
                            <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(0) }}>{0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 0</span></p>
                        </div>
                    )
                },
                {
                    title: 'Total Orders',
                    content: `$ ${0}`,
                    footer: (
                        <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                            {getTrendIcon(0)}
                            <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(0) }}>{0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 0</span></p>
                        </div>
                    )
                },
                {
                    title: 'Total sales value',
                    content: `$ 0`,
                    footer: (
                        <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                            {getTrendIcon(0)}
                            <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(0) }}>{0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 0</span></p>
                        </div>
                    )
                },
                {
                    title: 'Trips',
                    content: 0,
                    footer: (
                        <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                            {getTrendIcon(0)}
                            <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: getTextColor(0) }}>{0}% <span style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '12px', lineHeight: '14px', color: '#8B8B8B' }}>vs prev = 0</span></p>
                        </div>
                    )
                }
            ]
            setNumericals(cardsData)
            setCardsLoader(false)
        }
    }

    const getTreeStructureData = async () => {
        try {
            setChartLoader(true);
            const response: any = await getDashboardTreeData({
                products: categories,
                lastKey: [allTimes?.chart]
            });
            const items = response?.[categories[0]?.toLowerCase()]?.[0];
            const orgChart: any = {
                name: "Total revenue",
                value: `$ ${addComma(ceilNumber(items?.total_revenue))}`,
                children: [
                    {
                        name: "Revenue/customer",
                        value: `$ ${addComma(ceilNumber(items?.revenue_per_customers))}`,
                        children: [
                            {
                                name: "Revenue/purchase",
                                value: `$ ${addComma(ceilNumber(items?.revenue_per_purchase))}`,
                                children: [
                                ],
                            },
                            {
                                name: "Orders/customer",
                                value: `${addComma(ceilNumber(items?.orders_per_customer))}`,
                                children: [
                                    {
                                        name: "Avg price/purchase",
                                        value: `$ ${addComma(ceilNumber(items?.average_unit_price))}`,
                                    },
                                    {
                                        name: "Units/purchase",
                                        value: `${addComma(ceilNumber(items?.revenue_per_purchase))}`,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: "Customers",
                        value: `${items.total_active_customers}`,
                        children: [

                        ]
                    }
                ],
            };
            setTreeData(orgChart || {});
            setChartLoader(false)
        } catch (error) {
            setChartLoader(true);
            const orgChart: any = {
                name: "Total revenue",
                value: `$ 0`,
                children: [
                    {
                        name: "Revenue/customer",
                        value: `$ 0`,
                        children: [
                            {
                                name: "Revenue/purchase",
                                value: `$ 0`,
                                children: [
                                ],
                            },
                            {
                                name: "Orders/customer",
                                value: `0`,
                                children: [
                                    {
                                        name: "Avg price/purchase",
                                        value: "$ 0"
                                    },
                                    {
                                        name: "Units/purchase",
                                        value: "$ 0"
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: "Customers",
                        value: `0`,
                        children: [

                        ]
                    }
                ],
            };
            setTreeData(orgChart || {});
            setChartLoader(false);
        }
    }

    const getLoyalityChartData = async () => {
        try {
            setLoyaltyLoader(true)
            const response: any = await getDashboardLoyalityData({
                based_on: ['product_category'],
                brand: brand,
                timeline: [allTimes?.loyalty],
                products: categories
            })
            let data1 = response?.total;
            const getData1 = (name: any, data1: any) => {
                const data = data1.filter((item: any) => {
                    if (item.segment === name) {
                        return item
                    }
                })
                return data.length > 0 ? data[0] : { sales: 0 }
            }
            const finData = [getData1("shoppers", data1)?.sales, getData1("new_shoppers", data1)?.sales, getData1("activated_shoppers", data1)?.sales, getData1("retained_shoppers", data1)?.sales, getData1("lapsing_shoppers", data1)?.sales, getData1("repeating_shoppers", data1)?.sales];
            setLoyalityData(finData)
            setLoyaltyLoader(false)
        } catch (error) {
            setLoyaltyLoader(true)
            setLoyalityData([0, 0, 0, 0, 0, 0]);
            setLoyaltyLoader(false)
        }
    }

    const getCustomerChartData = async () => {
        try {
            setCustomerLoader(true)
            const data: any = await getDashboardCustomerData({
                brand: categories || ['alcohol'],
                timeline: [allTimes?.customer]
            })
            const finData = [data[0]?.customer_base_p1?.count, data[0]?.churners_to_competition?.count, data[0]?.churners_out_to_market?.count, data[0]?.recurring_customer?.count, data[0]?.new_comers_from_competition?.count, data[0]?.new_comers_on_market?.count, data[0]?.customer_base_p2?.count]
            setCustomerSwitchData(finData);
            setCustomerLoader(false)
        } catch (error) {
            setCustomerLoader(true)
            setCustomerSwitchData([0, 0, 0, 0, 0, 0, 0]);
            setCustomerLoader(false)
        }
    }

    const getPromotionsChartData = async () => {
        try {
            setPromotionsLoader(true)
            const data: any = await getDashboardPromotionsData({
                products: ['BETTY_CROCKER'],
                timeline: [allTimes?.promotions]
            });
            const dataArray = Object.entries(data?.total).map(([date, details]: any) => ({
                date,
                promo_0_sales: details?.promo_0.sales,
                promo_0_order: details?.promo_0.order,
                promo_1_sales: details?.promo_1.sales,
                promo_1_order: details?.promo_1.order
            }));
            const lastTenRecords = dataArray.splice(dataArray?.length - 10, dataArray?.length);
            const dateLabels = lastTenRecords?.map((item: any) => item?.date);
            const formattedDates = dateLabels.map((date: any) => formatDate(date));
            const finalDates = formattedDates?.map((date: any) => [date]);
            const nonPromo = lastTenRecords?.map((item: any) => item?.promo_0_sales);
            const promo = lastTenRecords?.map((item: any) => item?.promo_1_sales);
            setPromotionsData({ ...promotionsData, dateLabels: finalDates, nonPromos: nonPromo, promos: promo });
            setPromotionsLoader(false)
        } catch (error) {
            setPromotionsLoader(true)
            setPromotionsData({ ...promotionsData, dateLabels: [['Jan 2023'], ['Feb 2023'], ['Mar 2023'], ['Apr 2023'], ['May 2023'], ['Jun 2023']], nonPromos: [0, 0, 0, 0, 0, 0], promos: [0, 0, 0, 0, 0, 0] });
            setPromotionsLoader(false)
        }
    }

    const getBestSellersData = async () => {
        try {
            setBestsellersLoader(true);
            const data: any = await getDashboardBestSellers({
                based_on: ['product_category'],
                products: categories,
                // location: location,
                limit: 25,
                page: 1,
                timeline: [allTimes?.bestseller]
            });
            setBestSellers(data?.results);
            setBestsellersLoader(false);
        } catch (error) {
            setBestsellersLoader(true);
            setBestSellers([]);
            setBestsellersLoader(false);
        }
    }

    const getMapsData = async () => {
        try {
            setMapsLoader(true);
            const data: any = await getDashboardMap({
                products: categories,
                state: location,
                gender: ['Male'],
                timestamps: [allTimes?.map]
            });
            const filteredStates = usStateData.filter(state => state.state === data?.state)
                .map(state => ({ code: state.code, value: data.count }));

            const getalldata = () => {
                let countryData: any = {};
                filteredStates.forEach((obj) => {
                    countryData[obj.code] = obj.value;
                });
                return countryData;
            }
            setMapsData(getalldata())
            setMapsLoader(false);
        } catch (error) {
            setMapsLoader(true);
            setMapsData({});
            setMapsLoader(false);
        }
    }

    const getSegmentationData = async () => {
        try {
            setSegmentationLoader(true);
            const response: any = await getAutoStack({ products, category: categories });
            const rows: any = []
            const data = Object?.keys(response)?.map((item, index) => {
                rows.push({
                    title: `Target Segment ${index + 1}`,
                    segment: [
                        {
                            title: 'AOV',
                            content: response[item]['AOV']
                        },
                        {
                            title: 'TRIPS',
                            content: response[item]['TRIPS']
                        },
                        {
                            title: 'CUSTOMERS',
                            content: response[item]['CUSTOMERS']
                        }
                    ]
                })
            })
            setSegmentationData(rows);
            setSegmentationLoader(false);
        } catch (error) {
            setSegmentationLoader(false);
        }
    }
    useEffect(() => {
        getNumericalsData();
    }, [brand, categories, location]);

    useEffect(() => {
        if (allTimes?.bestseller) {
            getBestSellersData();
        }
    }, [brand, categories, allTimes?.bestseller, location])

    useEffect(() => {
        if (allTimes?.map) {
            getMapsData();
        }
    }, [brand, categories, allTimes?.map, location])

    useEffect(() => {
        if (allTimes?.chart) {
            getTreeStructureData();
        }
    }, [brand, categories, allTimes?.chart, location])

    useEffect(() => {
        if (allTimes?.loyalty) {
            getLoyalityChartData();
        }
    }, [brand, categories, allTimes?.loyalty, location])

    useEffect(() => {
        if (allTimes?.customer) {
            getCustomerChartData();
        }
    }, [brand, categories, allTimes?.customer, location])

    useEffect(() => {
        if (allTimes?.promotions) {
            getPromotionsChartData();
        }
    }, [brand, categories, allTimes?.promotions, location])

    useEffect(() => {
        if (allTimes?.segmentation) {
            getSegmentationData();
        }
    }, [brand, categories, allTimes?.segmentation, location])

    return {
        numericals,
        loader,
        setSelectBrand,
        selectBrand,
        brands,
        customerBrands,
        allTimes,
        timeLines,
        treeData,
        loyalityData,
        customerSwitchData,
        promotionsData,
        bestSellers,
        cardsLoader,
        chartLoader,
        loyaltyLoader,
        customerLoader,
        promotionsLoader,
        bestsellersLoader,
        mapsLoader,
        setAllTimes,
        mapsData,
        segmentationData,
        segmentationLoader,
        diagnoseKpis,
        actionKpis
    }
}