import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import SidebarNew from "../components/organisms/new-sidebar";
import styles from './styles.module.scss'
import { useSelector } from "react-redux";
import { Header } from "../pages/new-dashboard/components/header";
export function NewDashboardLayout(props: any) {
  const { showSide } = useSelector((state: any) => state?.headerContext);
  const isAuthenticated = () => {
    const accessToken = localStorage.getItem("token");
    return true; // Add your authentication logic here
  };
  return (
    <div className="d-flex" style={{ minHeight: '100vh', backgroundColor: '#F7F7F7' }}>
      {isAuthenticated() ? (
        <>
          <div style={{ position: 'fixed', width: '100%', zIndex: 1000, marginTop: '12px' }}>
            <Header />  {/* Place the Header at the top */}
          </div>
          {!showSide && <div
            style={{
              width: '100%',  // Set a fixed width similar to the image
              backgroundColor: '#F5F5F5',
              borderRight: '1px solid #E5E7EB', // Border to separate sidebar and main content
              paddingTop: '50px',
              position: 'fixed'
            }}
          >
            <SidebarNew />
          </div>}

          {/* Main content area */}
          <div
            className={styles.mainContent}
            style={{
              flexGrow: 1, // Takes the remaining space
              paddingLeft: !showSide ? '253px' : '12px'
            }}
          >
            <Outlet />
          </div>
        </>
      ) : (
        <Navigate to="/login" replace />
      )}
    </div>
  );
}
