import { Tab, Tabs } from "@mui/material";
import { FilterTabs } from "../charts/dailyCharts/tabPanel";
import { useState } from "react";
import styles from "./styles.module.scss"; // Import SCSS module
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import rightArrow from '../../../../assets/svg/sidebar/rightArrow.svg';
import leftArrow from '../../../../assets/svg/sidebar/leftArrow.svg';
import { setShowSidebar, setTab } from "../../../../store/header/reducer";
import logo from '../../../../assets/svg/xylicIcon.svg';
export const Header = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const { tab } = useSelector((state: any) => state?.headerContext);
    const [selectedFilter, setSelectedFilter] = useState(0);
    const { showSide } = useSelector((state: any) => state?.headerContext);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        // setSelectedTab(newValue);
        dispatch(setTab(newValue))
    };

    const handleFilterChange = (newFilter: number) => {
        setSelectedFilter(newFilter);
    };

    const tabData = [
        { label: 'Insights', title: '' },
        { label: 'Diagnose', title: '' },
        { label: 'Action', title: '' },
    ];

    const getIcon = () => {
        return showSide ? rightArrow : leftArrow
    }

    return (
        <div className={styles.headerContainer}>
            <div className={styles.logoContainer}>
                <div className={styles.logoChildContainer} onClick={() => navigate('/home')}>
                    <img src={logo} alt='logo' width={20} height={20} />
                    <span className={styles.logo_name}>Xylic</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0px', cursor: 'pointer' }} onClick={() => dispatch(setShowSidebar(!showSide))}>
                    <img src={getIcon()} alt="icon" />
                </div>
            </div>
            <div className={styles.container}>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    TabIndicatorProps={{
                        className: styles.tabIndicator, // Apply the SCSS class for the indicator
                    }}
                    className={styles.tabsContainer} // Apply the container styles
                >
                    {tabData.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            className={`${styles.tabRoot}`}
                            sx={{
                                minHeight: '30px',
                                minWidth: '30px', // Small width for each tab
                                padding: '0px', // No padding around tabs
                                margin: '0px 2px', // Compact spacing between tabs
                                fontWeight: 'bold',
                                fontSize: '10px', // Smaller font size to match the image
                                color: '#000', // Black color for non-selected tabs
                                '&.Mui-selected': { color: '#A259FF' }, // Purple color for selected tab
                            }}
                        />
                    ))}
                </Tabs>

                {/* Render the FilterTabs component below the tab selection */}
                <FilterTabs {...{ selectedFilter, onFilterChange: handleFilterChange }} />
            </div>
        </div>
    );
};
