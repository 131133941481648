import styles from './styles.module.scss'; // Importing SCSS module
import CardWrapper from './components/card-wrapper';
import { SalesChart } from './components/charts/dailyCharts/sales-chart';
import PyramidChart from './components/charts/pyramidChart';
import TopSearchQueries from './components/charts/top-search-queries';
import { KpiSection } from './components/charts/kpi-section';
import { PromotionsChart } from './components/charts/dailyCharts/PromotionChart';
import OrgChart from './components/charts/dailyCharts/OrgChart';
import { useSelector } from 'react-redux';

export const NewDashboard = () => {
    const { tab } = useSelector((state: any) => state?.headerContext);
    return (
        <div style={{ display: 'flex', gap: '12px' }}>
            {tab === 0 && <div className={styles.dashboardContainer}>
                <CardWrapper className={'salesChartContainer'}>
                    <SalesChart />
                </CardWrapper>
                <div style={{ display: 'flex', gap: '12px' }}>
                    <CardWrapper className={'shopperInteraction'} title="Top Search Queries" showHeader={true} showButton={true}>
                        <TopSearchQueries queries={[]} />
                    </CardWrapper>
                    <CardWrapper className={'shopperInteraction'} title="Shopper Interaction" showHeader={true}>
                        <PyramidChart />
                    </CardWrapper>
                </div>
            </div>}
            {tab === 1 && <div className={styles.dashboardContainer}>
                {/* <Header /> */}
                <CardWrapper className={'salesChartContainer'}>
                    <PromotionsChart />
                </CardWrapper>
                <CardWrapper className={'salesChartContainer'}>
                    <OrgChart />
                </CardWrapper>
            </div>}
            <div className={styles.kpiSection}>
                <KpiSection />
            </div>
        </div>
    );
};
