import React from "react";
import Tree from "react-d3-tree";
import styles from "./index.module.scss";

export default function OrgChartTree({ style, translate, treeData }: any) {
    const renderForeignObjectNode = ({
        nodeDatum,
        toggleNode,
        foreignObjectProps
    }: any) => {
        return (
            <g>
                <foreignObject {...foreignObjectProps}>
                    <div style={{
                        borderRadius: "5px",
                        backgroundColor: "#F4F0FE",
                        minHeight: '52px',
                        minWidth: '130px',
                        padding: '16px',
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <div>
                            <p style={{
                                fontFamily: 'Inter',
                                fontSize: '10px',
                                fontWeight: 400,
                                lineHeight: '20px',
                                color: "#000",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                width: "100px"
                            }}>{nodeDatum?.name}</p>
                            <p style={{
                                fontFamily: 'Inter',
                                fontSize: '10px',
                                fontWeight: 600,
                                lineHeight: '20px',
                                color: "#000"
                            }}>{nodeDatum?.value}</p>
                        </div>
                        {nodeDatum?.children && nodeDatum?.children?.length > 0 && (
                            <button style={{ width: "5px", border: 'none', cursor: "pointer", background: "none" }} onClick={toggleNode}>
                                {nodeDatum.__rd3t.collapsed ? "+" : "-" }
                            </button>
                        )}
                    </div>
                </foreignObject>
            </g>
        )
    };

    const foreignObjectProps = { width: '130px', height: '100px', x: 0, y: -35 };

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", ...style }}>
            {treeData && (
                <Tree
                    data={treeData}
                    orientation="horizontal"
                    translate={translate ? translate : { x: 250, y: 200 }} // Adjusted for centering
                    separation={{ siblings: 2, nonSiblings: 2 }}
                    collapsible={true}
                    pathFunc={"step"}
                    nodeSize={{
                        y: 78,
                        x: 170
                    }}
                    enableLegacyTransitions={true}
                    renderCustomNodeElement={(rd3tProps) =>
                        renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                    }
                    pathClassFunc={() => `${styles['custom-link']}`}
                />
            )}
        </div>
    );
}
