import React from 'react';
import styles from './styles.module.scss';

// Define a type for your query data
interface Query {
    name: string;
    volume: number;
}

const TopSearchQueries = ({ queries }: { queries: Query[] }) => {
    // Dummy data for now
    const data = [
        { name: 'Hamsa robe', volume: 80 },
        { name: 'Evil Eye', volume: 70 },
        { name: 'Kimono', volume: 65 },
        { name: 'Leather Bag', volume: 60 },
        { name: 'Office bag', volume: 55 },
        { name: 'Side Bag', volume: 50 },
        { name: 'Bag', volume: 45 }
    ];

    return (
        <div className={styles.container}>
            {/* Header Row */}
            <div className={styles.headerRow}>
                <span className={styles.headerText}>Query</span>
                <span className={styles.headerText}>Volume</span>
            </div>

            {/* Scrollable Data Rows */}
            <div className={styles.queryList}>
                {data.map((query, index) => (
                    <div key={index} className={styles.queryRow}>
                        <span className={styles.queryText}>{query.name}</span>
                        <div className={styles.volumeBar}>
                            <div
                                className={styles.volumeIndicator}
                                style={{ width: `${query.volume}%` }}
                            ></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TopSearchQueries;
