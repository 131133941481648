import styles from './sidebarLayout.module.scss';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import dashboard from '../../../assets/svg/sidebar/dashboard.svg';
import fav from '../../../assets/svg/sidebar/favourites.svg';
import ai from '../../../assets/svg/sidebar/xylicai.svg';
import archive from '../../../assets/svg/sidebar/archive.svg';
import plan from '../../../assets/svg/sidebar/plan.svg';
import creative from '../../../assets/svg/sidebar/creative.svg';
import launch from '../../../assets/svg/sidebar/lunh.svg';
import measure from '../../../assets/svg/sidebar/measure.svg';
import connections from '../../../assets/svg/sidebar/connections.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryData, getUtilsData } from '../../../services/insights';
import arrowDropdown from '../../../assets/svg/sidebar/arrow.svg';
import help from '../../../assets/svg/sidebar/help.svg'
import settings from '../../../assets/svg/sidebar/settings.svg'
import customerSegment from '../../../assets/svg/sidebar/costomerSegment.svg';
import locationSVG from '../../../assets/svg/sidebar/location.svg'
import fullfillment from '../../../assets/svg/sidebar/fullfillment.svg'
import promotions from '../../../assets/svg/sidebar/promotions.svg'
import orderType from '../../../assets/svg/sidebar/orderType.svg'

const SidebarNew = () => {
    const [state, setState] = useState({
        brand: [],
        category: [],
        delivery: [],
        products: [],
        location: [],
        age_group: []
    });

    const { showSide } = useSelector((state: any) => state?.headerContext);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch()
    useEffect(() => {
        getCategory();
        getBrands();
    }, []);

    const getCategory = async () => {
        const response: any = await getCategoryData();
        const data = response || [];
        setState({
            ...state, category: data
        });
    };

    const getBrands = async () => {
        try {
            const response: any = await getUtilsData('brand_name/?limit=10');
            const data = response?.results || [];
            setState({
                ...state, brand: data
            });
        } catch (error) {
            setState({
                ...state, brand: []
            });
        }
    };

    const CustomSidebarItem = ({ child }: any) => {
        const isActive = location.pathname === child?.path;
        return (
            <div
                className={styles.childContainer}
                onClick={() => navigate(child?.path)}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#F8FAFC'}
                onMouseLeave={(e) => !isActive && (e.currentTarget.style.backgroundColor = 'transparent')}
            >
                <img src={child.icon} alt='' width={20} height={20} />
                <div style={{ fontFamily: 'Inter', fontSize: '14px' }}>{child?.label}</div>
            </div>
        );
    };

    const dashboardData = [
        {
            label: '',
            children: [
                { label: 'Dashboard', icon: dashboard, path: '/insights' },
                { label: 'Favourites', icon: fav, path: '' },
                { label: 'Xylic Ai', icon: ai, path: '/xylic-ai' },
            ]
        },
        {
            label: 'Services',
            children: [
                { label: 'Plan', icon: plan, path: '' },
                { label: 'Creatives & Content', icon: creative, path: '' },
                { label: 'Launch Campaigns', icon: launch, path: '' },
                { label: 'Measure', icon: measure, path: '' },
                { label: 'Connections', icon: connections, path: '/connections' }
            ]
        },
        {
            label: 'Drill by',
            children: [
                { label: 'Customer Segments', icon: customerSegment, path: '/segments' },
                { label: 'Location', icon: locationSVG, path: '/location' },
                { label: 'Fulfillment Type', icon: fullfillment, path: '/fulfillment-type' },
                { label: 'Promotions Type', icon: promotions, path: '/promotions' },
                { label: 'Order Type', icon: orderType, path: '/order-type' },
            ]
        },
        {
            label: 'Product Level',
            children: [
                { label: 'Super Category', icon: arrowDropdown, path: '/super-category' },
                { label: 'Category', icon: arrowDropdown, path: '/category' },
                { label: 'Sub-Category', icon: arrowDropdown, path: '/sub-category' },
                { label: 'Segment', icon: arrowDropdown, path: '/segment' },
                { label: 'Sub-Segment', icon: arrowDropdown, path: '/sub-segment' },
                { label: 'Brand', icon: arrowDropdown, path: '/brand' },
                { label: 'Item', icon: arrowDropdown, path: '/item' },
            ]
        },
        {
            label: '',
            children: [
                { label: 'Help', icon: help, path: '/help' },
                { label: 'Archives', icon: archive, path: '/archives' },
                { label: 'Settings', icon: settings, path: '/settings' }
            ]
        },
    ];
    return (
        <div className={styles.sidebar} style={{ background: '#F5F5F5' }}>
            <ul className={styles.mainContainer} style={{}}>
                <div className={styles.scrollContainer} style={{ display: showSide ? 'none' : '' }}>
                    <div>
                        {dashboardData[0].children.map((child: any) => (
                            <CustomSidebarItem key={child.label} child={child} />
                        ))}
                    </div>
                    <div style={{ height: 'calc(100vh - 310px)', overflowY: 'auto' }}>
                        {dashboardData.slice(1, -1).map((item, index) => (
                            <div key={item.label}>
                                <div className={styles.DashTextHeader}>{item.label}</div>
                                {item.children.map((child: any) => (
                                    <CustomSidebarItem key={child.label} child={child} />
                                ))}
                            </div>
                        ))}
                    </div>
                    <div>
                        {dashboardData[4].children.map((child: any) => (
                            <CustomSidebarItem key={child.label} child={child} />
                        ))}
                    </div>
                </div>
            </ul>
        </div>
    );
};

export default SidebarNew;
