import HorizontalOrgChart from "../../../../../components/molecules/horizontal-org-chart"

const OrgChart = ({style, translate, treeData}: any) => {
  const treeData2 = {
    name: "Total Sales",
    value: "35,000",
    children: [
      {
        name: "Revenue Per Customer",
        value: "450",
        children: [
          {
            name: "Buyers",
            value: "678",
          },
        ],
      },
      {
        name: "Value Per Order",
        value: "450",
        children: [
          {
            name: "Orders",
            value: "50",
            children: [
              {
                name: "Promo",
                value: "5",
              },
              {
                name: "Non Promo",
                value: "10",
              },
            ],
          },
          {
            name: "Price Per Unit",
            value: "225",
            children: [
              {
                name: "Units",
                value: "2",
              },
            ],
          },
        ],
      },
    ],
  };
  
  return (
      <HorizontalOrgChart
        {...{
            style,
            translate,
            treeData:treeData2
        }}
    />
  )
}

export default OrgChart